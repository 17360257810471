import axios, { AxiosInstance, AxiosResponse } from 'axios';

export default class API {
    baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
    axiosInstance: AxiosInstance;

    get: <T>(url: string, data?: any) => Promise<AxiosResponse<T>>;
    post: <T>(url: string, data?: any) => Promise<AxiosResponse<T>>;
    put: <T>(url: string, data?: any) => Promise<AxiosResponse<T>>;
    patch: <T>(url: string, data?: any) => Promise<AxiosResponse<T>>;
    delete: <T>(url: string, data?: any) => Promise<AxiosResponse<T>>;

    constructor(token: string) {
        this.axiosInstance = axios.create({
            baseURL: this.baseUrl,
            timeout: 20000,
            headers: {
                ...(token != "" && { Authorization: `Bearer ${token}` }),
            },
        });

        axios.defaults.headers.post["Content-Type"] = "application/json";
        this.axiosInstance.defaults.transformRequest = [
            (data) => JSON.stringify(data),
        ];

        this.get = this.axiosInstance.get;
        this.post = this.axiosInstance.post;
        this.put = this.axiosInstance.put;
        this.patch = this.axiosInstance.patch;
        this.delete = this.axiosInstance.delete;
    }
}