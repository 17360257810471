import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';

import { configure } from 'mobx';
import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import { useRouter } from 'next/router';
import 'normalize.css/normalize.css';
import React, { ReactElement, ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Loading from '../common/components/Loading';
import { AuthStoreProvider, useAuthStore } from '../features/api/AuthStoreProvider';
import StoreData from '../features/api/StoreData';
import '../styles/globals.css';
import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OverlaysProvider } from '@blueprintjs/core';

type AuthProps = {
    children: ReactElement<any, any>;
};

configure({ enforceActions: 'never' });

const Auth = observer(function Auth({ children }: AuthProps) {
    const { loginWithRedirect, isLoading } = useAuth0();
    const router = useRouter();
    const authStore = useAuthStore();

    const whitelist = ['/auth0_callback'];

    if (authStore.isAuthenticated || whitelist.includes(router.pathname)) {
        return children;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                backgroundColor: '#282829',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <img src="/newlogo.svg" style={{ maxWidth: '90%' }} />
            <button className="login-button" onClick={() => loginWithRedirect()}>
                Login
            </button>
        </div>
    );
});

type Props = {
    Component: any;
    pageProps: any;
};

export default function VitruvianDashboard({ Component, pageProps }: Props) {
    const router = useRouter();
    return (
        <>
            <Head>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff8d00" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />

                <title>Vitruvian Admin</title>
            </Head>
            <AuthStoreProvider>
                <Auth0Provider
                    redirectUri={
                        (typeof window !== 'undefined'
                            ? `${window.location.protocol}//${window.location.host}`
                            : 'http://localhost:3000') + '/auth0_callback'
                    }
                    domain={'login.vitruvian.me'}
                    clientId={'bCubYPK7uUOYkmY9ct1GaBZrrOncyu75'}
                    audience="https://api.vitruvian.me">
                    <StoreData>
                        <Auth>
                            <DndProvider backend={HTML5Backend}>
                                <OverlaysProvider>
                                    <main>
                                        <Component {...pageProps} />
                                        <ToastContainer
                                            position="bottom-center"
                                            autoClose={4000}
                                            transition={Flip}
                                        />
                                    </main>
                                </OverlaysProvider>
                            </DndProvider>
                        </Auth>
                    </StoreData>
                </Auth0Provider>
            </AuthStoreProvider>
        </>
    );
}
